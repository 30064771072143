import React from "react";
import "../styles/ExperienceDetails.css"; // Optional CSS file for styling

const ExperienceDetails = ({ experience, onBack }) => {
  return (
    <div className="experience-details">
      <button onClick={onBack} className="back-button">Back to List</button>
      <h2>Experience Details</h2>
      <p><strong>Title:</strong> {experience.title}</p>
      <p><strong>Description:</strong> {experience.description || "N/A"}</p>
      <p><strong>Character:</strong> {experience.character?.name || "N/A"}</p>
      <p><strong>Status:</strong> {experience.status}</p>
      <p><strong>Transcription:</strong> {experience.transcription || "N/A"}</p>
      <p><strong>Response Script:</strong> {experience.responseScript || "N/A"}</p>
      <p><strong>Generated Audio:</strong> {experience.generatedAudioUrl ? (
        <a href={experience.generatedAudioUrl} target="_blank" rel="noopener noreferrer">Download Audio</a>
      ) : "N/A"}</p>
      <p><strong>Generated Video:</strong> {experience.generatedVideoUrl ? (
        <a href={experience.generatedVideoUrl} target="_blank" rel="noopener noreferrer">View Video</a>
      ) : "N/A"}</p>
      <p><strong>Uploaded File:</strong> {experience.uploadedFileUrl ? (
        <a href={experience.uploadedFileUrl} target="_blank" rel="noopener noreferrer">View File</a>
      ) : "N/A"}</p>
    </div>
  );
};

export default ExperienceDetails;
