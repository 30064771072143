import React, { useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "../styles/Profile.css";

const Profile = () => {
  const [profile, setProfile] = useState({
    name: "John Doe",
    email: "john.doe@example.com",
    picture: "", // No picture initially
  });
  const [image, setImage] = useState(null); // Original uploaded image
  const [crop, setCrop] = useState({ aspect: 1 }); // Square cropping
  const [croppedImageBlob, setCroppedImageBlob] = useState(null);
  const [showCropModal, setShowCropModal] = useState(false); // Toggle crop modal visibility

  // Handle file input or drag-and-drop
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result); // Base64 image for cropping
        setShowCropModal(true); // Open cropping modal
      };
      reader.readAsDataURL(file);
    }
  };

  // Open file input when clicking the profile image
  const handleImageClick = () => {
    document.getElementById("profile-picture-input").click();
  };

  // Generate cropped image blob
  const handleCropComplete = async (crop) => {
    if (image && crop.width && crop.height) {
      const croppedBlob = await getCroppedImage(image, crop);
      setCroppedImageBlob(croppedBlob);
    }
  };

  // Utility to crop the image
  const getCroppedImage = (imageSrc, crop) => {
    const canvas = document.createElement("canvas");
    const img = new Image();
    img.src = imageSrc;

    return new Promise((resolve) => {
      img.onload = () => {
        const scaleX = img.naturalWidth / img.width;
        const scaleY = img.naturalHeight / img.height;
        const ctx = canvas.getContext("2d");

        canvas.width = crop.width;
        canvas.height = crop.height;

        ctx.drawImage(
          img,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          crop.width,
          crop.height
        );

        canvas.toBlob((blob) => resolve(blob), "image/jpeg", 1);
      };
    });
  };

  // Submit cropped image to the backend
  const handleSubmit = async () => {
    if (croppedImageBlob) {
      const formData = new FormData();
      formData.append("profilePicture", croppedImageBlob);

      try {
        const token = localStorage.getItem("authToken");
        const response = await fetch("/api/users/profile-picture", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        if (response.ok) {
          const updatedProfile = await response.json();
          setProfile((prevProfile) => ({
            ...prevProfile,
            picture: updatedProfile.profilePicture,
          }));
          console.log("Profile picture updated successfully");
        } else {
          console.error("Failed to update profile picture");
        }
      } catch (error) {
        console.error("Error updating profile picture:", error);
      }
    }
    setShowCropModal(false); // Close cropping modal
  };

  // Get the first letter of the username
  const getInitial = () => (profile.name ? profile.name.charAt(0).toUpperCase() : "?");

  return (
    <div className="profile-page">
      <div className="profile-image-container">
        <div className="profile-picture-wrapper" onClick={handleImageClick}>
          {profile.picture ? (
            <img
              src={profile.picture}
              alt="Profile"
              className="profile-picture-large"
            />
          ) : (
            <div className="default-profile-picture">
              <span className="initial">{getInitial()}</span>
            </div>
          )}
          <div className="profile-picture-overlay">
            <span>+</span>
          </div>
        </div>
      </div>
      <div className="profile-container">
        <h2>Profile Settings</h2>
        <p>Name: {profile.name}</p>
        <p>Email: {profile.email}</p>
      </div>

      {showCropModal && (
        <div className="crop-modal">
          <div className="crop-modal-content">
            <h3>Crop Your Profile Picture</h3>
            {image && (
              <ReactCrop
                src={image}
                crop={crop}
                onChange={(newCrop) => setCrop(newCrop)}
                onComplete={handleCropComplete}
              />
            )}
            <button onClick={handleSubmit}>Save Profile Picture</button>
            <button onClick={() => setShowCropModal(false)}>Cancel</button>
          </div>
        </div>
      )}

      <input
        type="file"
        accept="image/jpeg, image/png"
        onChange={handleFileChange}
        style={{ display: "none" }}
        id="profile-picture-input"
      />
    </div>
  );
};

export default Profile;
