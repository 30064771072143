import React, { useState } from "react";
import CharacterManagement from "../components/CharacterManagement";
import "../styles/Admin.css";

const Admin = () => {
  const [selectedTab, setSelectedTab] = useState("characters"); // Manage tabs

  return (
    <div className="admin-container">
      <h1>Admin Panel</h1>
      <div className="tabs">
        <button
          className={`tab-button ${selectedTab === "characters" ? "active" : ""}`}
          onClick={() => setSelectedTab("characters")}
        >
          Characters
        </button>
        {/* Add more tabs here for other entities in the future */}
      </div>
      <div className="tab-content">
        {selectedTab === "characters" && <CharacterManagement />}
        {/* Add more conditional renders for other tabs here */}
      </div>
    </div>
  );
};

export default Admin;
