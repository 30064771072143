import React, { useState, useEffect } from "react";
import ExperienceDetails from "./ExperienceDetails"; // Import the new component
import "../styles/ExperienceList.css"; // Optional CSS file for styling

const API_URL = process.env.REACT_APP_API_URL;

const ExperienceList = () => {
  const [experiences, setExperiences] = useState([]);
  const [error, setError] = useState("");
  const [selectedExperience, setSelectedExperience] = useState(null); // State for the selected experience
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchExperiences = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          setError("User not authenticated. Please log in.");
          setIsLoading(false);
          return;
        }

        const response = await fetch(`${API_URL}/api/experiences`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setExperiences(data);
          setError("");
        } else {
          const errorData = await response.json();
          setError(errorData.message || "Failed to fetch experiences.");
        }
      } catch (err) {
        console.error("Error fetching experiences:", err);
        setError("An error occurred while fetching experiences.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchExperiences();

    // Optional: Polling for status updates every 30 seconds
    const interval = setInterval(fetchExperiences, 30000);
    return () => clearInterval(interval);
  }, []);

  const handleViewExperience = (experience) => {
    setSelectedExperience(experience); // Set the selected experience to display details
  };

  const handleBackToList = () => {
    setSelectedExperience(null); // Clear the selected experience to return to the list
  };

  const handleRetry = async (id) => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await fetch(`${API_URL}/api/experiences/${id}/process`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        alert("Experience reprocessing started successfully.");
      } else {
        const errorData = await response.json();
        alert(`Failed to start reprocessing: ${errorData.message}`);
      }
    } catch (err) {
      console.error("Error retrying experience:", err);
      alert("An error occurred while retrying the experience.");
    }
  };

  return (
    <div className="experience-list-container">
      {selectedExperience ? (
        <ExperienceDetails experience={selectedExperience} onBack={handleBackToList} />
      ) : (
        <div className="experience-list">
          <h2>Your Experiences</h2>
          {error && <p className="error">{error}</p>}
          {isLoading ? (
            <p>Loading experiences...</p>
          ) : experiences.length === 0 ? (
            <p>No experiences available. Please create a new experience to view it here.</p>
          ) : (
            <ul>
              {experiences.map((experience) => (
                <li key={experience._id}>
                  <div
                    className="experience-item"
                    onClick={() => handleViewExperience(experience)}
                  >
                    <h3>{experience.title}</h3>
                    <p>Character: {experience.character?.name || "Unknown"}</p>
                    <p>Status: {experience.status}</p>
                  </div>
                  {experience.status === "Failed" && (
                    <button
                      className="retry-button"
                      onClick={() => handleRetry(experience._id)}
                    >
                      Retry
                    </button>
                  )}
                  {experience.status === "Completed" && (
                    <div className="generated-links">
                      {experience.generatedVideoSignedUrl && (
                        <a
                          href={experience.generatedVideoSignedUrl} // Use the signed URL
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Video
                        </a>
                      )}
                    </div>
                  )}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default ExperienceList;
