import React, { useState } from "react";
import "../styles/LoginRegister.css";
import { useSetRecoilState } from 'recoil';
import { userState, authTokenState } from '../atoms/userAtoms';

const API_URL = process.env.REACT_APP_API_URL;

const LoginRegister = ({ onLogin, onRegister }) => {
  const setUser = useSetRecoilState(userState);
  const setAuthToken = useSetRecoilState(authTokenState);
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState(""); // For registration
  const [message, setMessage] = useState(""); // Feedback message
  const [forgotPasswordMode, setForgotPasswordMode] = useState(false);

  const toggleMode = () => {
    setIsLogin(!isLogin);
    setMessage(""); // Clear any feedback messages
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/api/users/login`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        // Update Recoil state
        setAuthToken(data.token);
        setUser({
          id: data.id,
          name: data.name,
          email: data.email,
          credits: data.credits,
          profilePicture: data.profilePicture,
        });
        
        // Still store token in localStorage for persistence
        localStorage.setItem("authToken", data.token);
        
        if (onLogin) onLogin(data);
      } else {
        setMessage(data.message || "Login failed. Please try again.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/api/users/register`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name, email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage("Registration successful! Please check your email to verify your account.");
        if (onRegister) onRegister(data);
      } else {
        setMessage(data.message || "Registration failed. Please try again.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_URL}/api/users/forgot-password`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage("Password reset email sent! Please check your inbox.");
      } else {
        setMessage(data.message || "Failed to send password reset email. Please try again.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
    }
  };

  return (
    <div className="login-register-container no-shadow">
      <div className="form-container">
        <h1>
          {forgotPasswordMode
            ? "Forgot Password"
            : isLogin
            ? "Login"
            : "Register"}
        </h1>
        {message && <p className="feedback-message">{message}</p>}
        {!forgotPasswordMode ? (
          <form onSubmit={isLogin ? handleLogin : handleRegister}>
            {!isLogin && (
              <div>
                <label>Username</label>
                <input
                  type="text"
                  placeholder="Enter your username"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                />
              </div>
            )}
            <div>
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Password</label>
              <input
                type="password"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit">{isLogin ? "Login" : "Register"}</button>
          </form>
        ) : (
          <form onSubmit={handleForgotPassword}>
            <div>
              <label>Email</label>
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button type="submit">Send Reset Email</button>
          </form>
        )}
        {!forgotPasswordMode && (
          <p>
            {isLogin ? "Don't have an account?" : "Already have an account?"}{" "}
            <span onClick={toggleMode} className="toggle-link">
              {isLogin ? "Register" : "Login"}
            </span>
          </p>
        )}
        {isLogin && (
          <p>
            Forgot your password?{" "}
            <span
              onClick={() => {
                setForgotPasswordMode(true);
                setMessage("");
              }}
              className="toggle-link"
            >
              Reset it here
            </span>
          </p>
        )}
        {forgotPasswordMode && (
          <p>
            Remembered your password?{" "}
            <span
              onClick={() => {
                setForgotPasswordMode(false);
                setMessage("");
              }}
              className="toggle-link"
            >
              Go back to Login
            </span>
          </p>
        )}
      </div>
    </div>
  );
};

export default LoginRegister;
