import { useEffect } from "react";
import * as THREE from "three";
import snowflake1 from "../images/snowflake1.png"; // Import snowflake images
import snowflake2 from "../images/snowflake2.png";
import snowflake3 from "../images/snowflake3.png";
import snowflake4 from "../images/snowflake4.png";
import snowflake5 from "../images/snowflake5.png";

const WhiteSnowScene = () => {
  useEffect(() => {
    // Create a Three.js scene
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ alpha: true }); // Transparent background
    renderer.setSize(window.innerWidth, window.innerHeight);

    // Append the canvas to the body
    renderer.domElement.style.position = "fixed";
    renderer.domElement.style.top = 0;
    renderer.domElement.style.left = 0;
    renderer.domElement.style.width = "100%";
    renderer.domElement.style.height = "100%";
    renderer.domElement.style.zIndex = "-1"; // Ensure it's behind other elements
    document.body.appendChild(renderer.domElement);

    // Load the background texture from the imported image
    const loader = new THREE.TextureLoader();
    loader.load(
      (texture) => {
        scene.background = texture; // Set the background texture
      },
      undefined,
      (error) => {
        // Handle error if the image fails to load
      }
    );

    // Array of snowflake textures
    const snowflakeTextures = [
      new THREE.TextureLoader().load(snowflake1),
      new THREE.TextureLoader().load(snowflake2),
      new THREE.TextureLoader().load(snowflake3),
      new THREE.TextureLoader().load(snowflake4),
      new THREE.TextureLoader().load(snowflake5),
    ];

    // Create snowflake material with transparency
    const createSnowflakeMaterial = (texture) => {
      return new THREE.MeshBasicMaterial({
        map: texture,
        transparent: true, // Ensures background is transparent
      });
    };

    // Generate snowflakes
    const snowflakes = [];
    for (let i = 0; i < 200; i++) {
      const texture = snowflakeTextures[Math.floor(Math.random() * snowflakeTextures.length)];
      const material = createSnowflakeMaterial(texture);

      const geometry = new THREE.PlaneGeometry(0.5, 0.5); // Flat plane for snowflake
      const snowflake = new THREE.Mesh(geometry, material);

      // Randomize snowflake position
      snowflake.position.set(
        Math.random() * 20 - 10,
        Math.random() * 20 - 10,
        Math.random() * 20 - 10
      );

      // Randomize rotation for variety
      snowflake.rotation.z = Math.random() * Math.PI * 2;

      scene.add(snowflake);
      snowflakes.push(snowflake);
    }

    // Set camera position
    camera.position.z = 5;

    // Handle resizing
    const handleResize = () => {
      renderer.setSize(window.innerWidth, window.innerHeight);
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
    };
    window.addEventListener("resize", handleResize);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);

      snowflakes.forEach((snowflake) => {
        snowflake.position.y -= 0.05; // Move down
        if (snowflake.position.y < -10) {
          snowflake.position.y = 10; // Reset position
        }
      });

      renderer.render(scene, camera);
    };
    animate();

    // Clean up the scene on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      document.body.removeChild(renderer.domElement);
    };
  }, []);

  return null; // This component only renders the Three.js scene
};

export default WhiteSnowScene;
