import React, { useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authTokenState } from "../atoms/userAtoms";

// Initialize Stripe with the publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const BuyCredits = () => {
  const navigate = useNavigate();
  const authToken = useRecoilValue(authTokenState);

  // Redirect to login if user is not authenticated
  useEffect(() => {
    if (!authToken) {
      alert("You are not authorized. Please log in.");
      navigate("/login");
    }
  }, [authToken, navigate]);

  const handlePurchase = async (credits) => {
    try {
      if (!authToken) {
        alert("You are not authorized. Please log in.");
        navigate("/login");
        return;
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/stripe/create-checkout-session`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`, // Include the token
        },
        body: JSON.stringify({ credits }),
      });

      const data = await response.json();

      if (!data.sessionId) {
        console.error("Failed to create checkout session:", data.message);
        alert("Failed to create checkout session. Please try again.");
        return;
      }

      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({ sessionId: data.sessionId });

      if (result.error) {
        console.error("Stripe Checkout error:", result.error.message);
        alert("An error occurred during checkout. Please try again.");
      }
    } catch (error) {
      console.error("Error during purchase:", error.message);
      alert("An error occurred. Please try again.");
    }
  };

  return (
    <div style={{ padding: "20px", maxWidth: "400px", margin: "auto", textAlign: "center" }}>
      <h2>Buy Credits</h2>
      <p>Purchase credits to use with our services:</p>
      <div style={{ margin: "20px 0" }}>
        <button
          onClick={() => handlePurchase("1")}
          style={{
            display: "block",
            width: "100%",
            padding: "10px",
            marginBottom: "10px",
            backgroundColor: "#007BFF",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Buy 1 Credit - $2.50
        </button>
        <button
          onClick={() => handlePurchase("4")}
          style={{
            display: "block",
            width: "100%",
            padding: "10px",
            backgroundColor: "#28A745",
            color: "white",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          Buy 4 Credits - $10.00
        </button>
      </div>
      <p>Credits can be used to create amazing videos with our platform.</p>
    </div>
  );
};

export default BuyCredits;
