import React, { createContext, useState, useContext, useEffect } from "react";

// Create the UserContext
const UserContext = createContext();

// UserProvider component to wrap the application and provide the context
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState({
    id: null,
    name: "",
    email: "",
    credits: 0,
    profilePicture: null,
  });

  const [authToken, setAuthToken] = useState(localStorage.getItem("authToken") || "");

  // Fetch user profile and credits on mount or when the authToken changes
  useEffect(() => {
    const fetchUserData = async () => {
      if (!authToken) return;

      try {
        const response = await fetch("/api/users/profile", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUser({
            id: data.id,
            name: data.name,
            email: data.email,
            credits: data.credits,
            profilePicture: data.profilePicture,
          });
        } else {
          console.error("Failed to fetch user profile");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [authToken]);

  /**
   * Update the user's credits
   * @param {number} newCredits - The updated credits value
   */
  const updateCredits = (newCredits) => {
    setUser((prevUser) => ({
      ...prevUser,
      credits: newCredits,
    }));
  };

  /**
   * Update the user's data (e.g., profile, name)
   * @param {Object} userData - The updated user data
   */
  const updateUser = (userData) => {
    setUser((prevUser) => ({
      ...prevUser,
      ...userData,
    }));
  };

  /**
   * Update the authentication token
   * @param {string} token - The new auth token
   */
  const updateAuthToken = (token) => {
    localStorage.setItem("authToken", token);
    setAuthToken(token);
  };

  /**
   * Log the user out
   */
  const logout = () => {
    localStorage.removeItem("authToken");
    setAuthToken("");
    setUser({
      id: null,
      name: "",
      email: "",
      credits: 0,
      profilePicture: null,
    });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        authToken,
        updateCredits,
        updateUser,
        updateAuthToken,
        logout,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook to use the UserContext
export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
