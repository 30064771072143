import React from "react";
import { Link } from "react-router-dom";
import "../styles/Footer.css"; // Optional: Create a CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <p>
        By using this site, you agree to our{" "}
        <Link to="/tos">Terms of Service</Link> and{" "}
        <Link to="/privacypolicy">Privacy Policy</Link>.
      </p>
    </footer>
  );
};

export default Footer;
