import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
import "../styles/Sidebar.css";

const Sidebar = ({ handleLogout }) => {
  const { user } = useUser(); // Access user data from UserContext
  const navigate = useNavigate(); // Navigation hook for redirecting

  // Get the first letter of the username
  const getInitial = () => (user.name ? user.name.charAt(0).toUpperCase() : "?");

  // Handle user logout
  const handleUserLogout = () => {
    handleLogout(); // Call logout logic passed from App.js
    navigate("/login"); // Redirect to LoginRegister page
  };

  return (
    <nav className="sidebar">
      <div className="sidebar-header">
        <h1 className="logo">WonderGram</h1>
        <div
          className="profile-section"
          onClick={() => navigate("/profile")}
          style={{ cursor: "pointer" }}
        >
          {user.profilePicture ? (
            <img
              src={user.profilePicture}
              alt="Profile"
              className="sidebar-profile-picture"
            />
          ) : (
            <div className="default-sidebar-profile-picture">
              <span className="sidebar-initial">{getInitial()}</span>
            </div>
          )}
        </div>
        <div className="credits">
          <span className="credit-icon">💰</span>
          <span>{user.credits} Credits</span>
        </div>
      </div>
      <ul>
        <li>
          <NavLink
            to="/experience"
            className={({ isActive }) =>
              isActive ? "menu-item active" : "menu-item"
            }
          >
            Your Experiences
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/create"
            className={({ isActive }) =>
              isActive ? "menu-item active" : "menu-item"
            }
          >
            Create New Experience
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/credits"
            className={({ isActive }) =>
              isActive ? "menu-item active" : "menu-item"
            }
          >
            Buy Credits
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admin"
            className={({ isActive }) =>
              isActive ? "menu-item active" : "menu-item"
            }
          >
            Admin
          </NavLink>
        </li>
        <li className="logout-button" onClick={handleUserLogout}>
          <span>Logout</span>
          <i className="logout-icon">🔒</i>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
