import React from "react";

const PrivacyPolicy = () => (
  <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
    <h1>Privacy Policy</h1>
    <p>Effective Date: 11/27/2024</p>

    <h2>Introduction</h2>
    <p>
      Welcome to Wondergram. Your privacy is important to us, and we are committed to protecting the personal information you share with us. This Privacy Policy explains how we collect, use, and safeguard your information when you use our platform.
    </p>

    <h2>Information We Collect</h2>
    <ul>
      <li>
        <strong>Personal Information:</strong> When you create an account, we may collect your name, email address, and profile photo.
      </li>
      <li>
        <strong>Usage Data:</strong> We collect information about how you interact with our platform, such as pages visited and actions taken.
      </li>
    </ul>

    <h2>How We Use Your Information</h2>
    <ul>
      <li>To provide and maintain our services</li>
      <li>To improve and personalize your experience</li>
      <li>To communicate with you, including for customer support</li>
      <li>To comply with legal obligations</li>
    </ul>

    <h2>Sharing Your Information</h2>
    <p>We do not sell your personal information to third parties. We may share your information with service providers who help us operate our platform or comply with legal obligations.</p>

    <h2>Your Rights</h2>
    <p>
      You have the right to access, update, or delete your personal information. If you have any questions or concerns about your privacy, please contact us at [support@wondergrams.net].
    </p>

    <h2>Changes to This Policy</h2>
    <p>
      We may update this Privacy Policy from time to time. Please check this page periodically for updates. Your continued use of our platform constitutes your acceptance of any changes.
    </p>
  </div>
);

export default PrivacyPolicy;
