import React, { useEffect, useState } from "react";
import "../styles/CharacterManagement.css";

const API_URL = process.env.REACT_APP_API_URL;

const CharacterManagement = () => {
  const [characters, setCharacters] = useState([]);
  const [newCharacterName, setNewCharacterName] = useState("");
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [avatarName, setAvatarName] = useState("");
  const [avatarFile, setAvatarFile] = useState(null);
  const [avatarVoiceId, setAvatarVoiceId] = useState("");
  const [selectedAvatar, setSelectedAvatar] = useState(null);

  const [templateVideoFile, setTemplateVideoFile] = useState(null);
  const [templateThumbnailFile, setTemplateThumbnailFile] = useState(null);
  const [scriptTemplate, setScriptTemplate] = useState("");
  const [templateTags, setTemplateTags] = useState("");

  useEffect(() => {
    const fetchCharacters = async () => {
      try {
        const token = localStorage.getItem("authToken");
        if (!token) {
          console.error("User not authenticated. Please log in.");
          return;
        }

        const response = await fetch(`${API_URL}/api/characters`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setCharacters(data); // Include signed URLs for avatars in the state
        } else {
          console.error("Failed to fetch characters");
        }
      } catch (error) {
        console.error("Error fetching characters:", error);
      }
    };

    fetchCharacters();
  }, []);

  const handleAddCharacter = async () => {
    try {
      const response = await fetch(`${API_URL}/api/characters`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: newCharacterName, avatars: [] }),
      });

      if (response.ok) {
        const newCharacter = await response.json();
        setCharacters((prev) => [...prev, newCharacter]);
        setNewCharacterName("");
      } else {
        console.error("Failed to add character");
      }
    } catch (error) {
      console.error("Error adding character:", error);
    }
  };

  const handleAddAvatar = async () => {
    try {
      if (!selectedCharacter || !avatarFile) return;

      const formData = new FormData();
      formData.append("characterId", selectedCharacter._id);
      formData.append("name", avatarName);
      formData.append("photo", avatarFile);
      formData.append("voiceId", avatarVoiceId);

      const response = await fetch(
        `${API_URL}/api/characters/${selectedCharacter._id}/avatars`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const updatedCharacter = await response.json();
        setCharacters((prev) =>
          prev.map((char) => (char._id === updatedCharacter._id ? updatedCharacter : char))
        );
        setAvatarName("");
        setAvatarFile(null);
        setAvatarVoiceId("");
      } else {
        console.error("Failed to add avatar");
      }
    } catch (error) {
      console.error("Error adding avatar:", error);
    }
  };

  const handleAddTemplate = async () => {
    try {
      if (!selectedCharacter || !selectedAvatar || !templateVideoFile || !templateThumbnailFile) {
        console.error("Missing required template fields");
        return;
      }

      const formData = new FormData();
      formData.append("videoTemplate", templateVideoFile);
      formData.append("thumbnailTemplate", templateThumbnailFile);
      formData.append("scriptTemplate", scriptTemplate);
      formData.append("tags", templateTags);

      const response = await fetch(
        `${API_URL}/api/characters/${selectedCharacter._id}/avatars/${selectedAvatar._id}/templates`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const updatedCharacter = await response.json();
        setCharacters((prev) =>
          prev.map((char) => (char._id === updatedCharacter._id ? updatedCharacter : char))
        );
        setTemplateVideoFile(null);
        setTemplateThumbnailFile(null);
        setScriptTemplate("");
        setTemplateTags("");
      } else {
        console.error("Failed to add template");
      }
    } catch (error) {
      console.error("Error adding template:", error);
    }
  };

  const handleDeleteAvatar = async (avatarId) => {
    try {
      if (!selectedCharacter) return;

      const response = await fetch(
        `${API_URL}/api/characters/${selectedCharacter._id}/avatars/${avatarId}`,
        { method: "DELETE" }
      );

      if (response.ok) {
        const updatedCharacter = await response.json();
        setCharacters((prev) =>
          prev.map((char) => (char._id === updatedCharacter._id ? updatedCharacter : char))
        );
      } else {
        console.error("Failed to delete avatar");
      }
    } catch (error) {
      console.error("Error deleting avatar:", error);
    }
  };

  return (
    <div className="character-management">
      <h2>Manage Characters</h2>
      <div className="add-character">
        <input
          type="text"
          placeholder="New character name"
          value={newCharacterName}
          onChange={(e) => setNewCharacterName(e.target.value)}
        />
        <p></p>
        <button onClick={handleAddCharacter}>Add Character</button>
      </div>

      <div className="character-list">
        <h3>Existing Characters</h3>
        <ul>
          {characters.map((char) => (
            <li
              key={char._id}
              className={selectedCharacter?._id === char._id ? "selected" : ""}
              onClick={() => {
                setSelectedCharacter(char);
                setSelectedAvatar(null);
              }}
            >
              {char.name}
            </li>
          ))}
        </ul>
      </div>

      {selectedCharacter && (
        <div className="avatar-management">
          <h3>Manage Avatars for {selectedCharacter.name}</h3>
          <div className="add-avatar">
            <h4>Add Avatar</h4>
            <div className="form-group">
              <label htmlFor="avatarName">Avatar Name:</label>
              <input
                id="avatarName"
                type="text"
                placeholder="Avatar name"
                value={avatarName}
                onChange={(e) => setAvatarName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="avatarPhoto">Avatar Photo:</label>
              <input
                id="avatarPhoto"
                type="file"
                accept="image/png, image/jpeg"
                onChange={(e) => setAvatarFile(e.target.files[0])}
              />
            </div>
            <div className="form-group">
              <label htmlFor="avatarVoiceId">Voice ID:</label>
              <input
                id="avatarVoiceId"
                type="text"
                placeholder="Voice ID"
                value={avatarVoiceId}
                onChange={(e) => setAvatarVoiceId(e.target.value)}
              />
            </div>
            <button onClick={handleAddAvatar}>Add Avatar</button>
          </div>

          <div className="avatar-list">
            <h4>Existing Avatars</h4>
            <ul>
              {selectedCharacter.avatars.map((avatar) => (
                <li
                  key={avatar._id}
                  className={selectedAvatar?._id === avatar._id ? "selected" : ""}
                  onClick={() => setSelectedAvatar(avatar)}
                >
                  <span>{avatar.name}</span>
                  <img
                    src={avatar.signedPhotoUrl || avatar.photo} // Use signedPhotoUrl if available, fallback to photo
                    alt={avatar.name}
                    className="avatar-photo"
                  />
                  <span>Voice ID: {avatar.voiceId}</span>
                  <button onClick={() => handleDeleteAvatar(avatar._id)}>Delete</button>
                </li>
              ))}
            </ul>
          </div>

          {selectedAvatar && (
            <div className="template-management">
              <h4>Manage Templates for {selectedAvatar.name}</h4>
              <div className="add-template">
                <div className="form-group">
                  <label htmlFor="templateVideoFile">Template Video File:</label>
                  <input
                    id="templateVideoFile"
                    type="file"
                    accept="video/mp4"
                    onChange={(e) => setTemplateVideoFile(e.target.files[0])}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="templateThumbnailFile">Template Thumbnail File:</label>
                  <input
                    id="templateThumbnailFile"
                    type="file"
                    accept="image/png, image/jpeg"
                    onChange={(e) => setTemplateThumbnailFile(e.target.files[0])}
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="scriptTemplate">Script Template:</label>
                  <textarea
                    id="scriptTemplate"
                    placeholder="Enter Script Template"
                    value={scriptTemplate}
                    onChange={(e) => setScriptTemplate(e.target.value)}
                    rows={5} //Adjust the number of rows as needed
                    cols={50} //Adjust the number of columns as needed
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="templateTags">Tags (comma-separated):</label>
                  <input
                    id="templateTags"
                    type="text"
                    placeholder="Tags"
                    value={templateTags}
                    onChange={(e) => setTemplateTags(e.target.value)}
                  />
                </div>
                <button 
                  onClick={handleAddTemplate}
                  disabled={!templateVideoFile || !templateThumbnailFile || !scriptTemplate}
                >
                  Add Template
                </button>
              </div>

              <div className="template-list">
                <h5>Existing Templates</h5>
                <ul>
                  {selectedAvatar.avatarTemplates.map((template) => (
                    <li key={template._id}>
                      <span>Video:</span>
                      <a href={template.videoTemplateUrl} target="_blank" rel="noopener noreferrer">
                        {template.videoTemplateUrl}
                      </a>
                      <img
                        src={template.videoTemplateThumbnailUrl}
                        alt="Thumbnail"
                        className="template-thumbnail"
                      />
                      <span>Script Template: {template.scriptTemplate}</span>
                      <span>Tags: {template.tags.join(", ")}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CharacterManagement;
