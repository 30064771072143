import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
const API_URL = process.env.REACT_APP_API_URL;

const ExperienceDownload = () => {
  const { id } = useParams(); // Get the experience ID from the route
  const [experience, setExperience] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Fetch experience details by ID  
    const fetchExperience = async () => {
      try {
        const response = await fetch(`${API_URL}/api/experiences/${id}`);
        const data = await response.json();
        setExperience(data);
        setLoading(false);
      } catch (err) {
        setError("Failed to load experience. Please try again later.");
        setLoading(false);
      }
    };

    fetchExperience();
  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = experience.generatedVideoSignedUrl; // Assuming `generatedVideoSignedUrl` contains the URL for downloading
    link.download = `experience-${id}.mp4`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({
          title: "Check out this video!",
          url: experience.generatedVideoSignedUrl, // Assuming `generatedVideoSignedUrl` contains the shareable link
        })
        .catch((err) => console.error("Error sharing:", err));
    } else {
      alert("Sharing is not supported in your browser.");
    }
  };

  return (
    <div style={{ textAlign: "center", padding: "20px" }}>
      <h1>Experience Details</h1>
      <video
        width="600"
        controls
        src={experience.generatedVideoSignedUrl} // Assuming `generatedVideoSignedUrl` contains the video source URL
        onClick={(e) => e.target.play()}
      >
        Your browser does not support the video tag.
      </video>
      <div style={{ marginTop: "20px" }}>
        <button onClick={handleDownload} style={{ marginRight: "10px" }}>
          Download Video
        </button>
        <button onClick={handleShare}>Share Video</button>
      </div>
    </div>
  );
};

export default ExperienceDownload;
