import React from "react";

const TermsOfService = () => (
  <div style={{ padding: "20px", maxWidth: "800px", margin: "0 auto" }}>
    <h1>Terms of Service</h1>
    <p>Effective Date: 11/27/2024</p>

    <h2>Introduction</h2>
    <p>
      Welcome to Wondergrams. By accessing or using our platform, you agree to be bound by these Terms of Service. If you do not agree, please do not use our platform.
    </p>

    <h2>Use of the Platform</h2>
    <ul>
      <li>You must be at least 13 years old to use Wondergrams.</li>
      <li>You agree to provide accurate and complete information during registration.</li>
      <li>You are responsible for maintaining the confidentiality of your account credentials.</li>
    </ul>

    <h2>Prohibited Activities</h2>
    <ul>
      <li>Using the platform for unlawful purposes</li>
      <li>Posting harmful, offensive, or inappropriate content</li>
      <li>Attempting to hack, disrupt, or exploit the platform</li>
    </ul>

    <h2>Intellectual Property</h2>
    <p>
      All content, trademarks, and logos on Wondergrams are the property of their respective owners. You may not use them without permission.
    </p>

    <h2>Termination</h2>
    <p>
      We reserve the right to terminate or suspend your account if you violate these Terms of Service or engage in harmful activities.
    </p>

    <h2>Changes to These Terms</h2>
    <p>
      We may update these Terms of Service at any time. Please review them periodically. Your continued use of the platform constitutes your acceptance of any changes.
    </p>
  </div>
);

export default TermsOfService;
