import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { UserProvider } from "./context/UserContext"; // Import UserProvider
import "./App.css";
import { RecoilRoot } from "recoil";
import Profile from "./pages/Profile";
import LoginRegister from "./pages/LoginRegister";
import Admin from "./pages/Admin";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsOfService from "./components/TermsOfService";
import SnowScene from "./components/SnowScene";
import Sidebar from "./components/Sidebar";
import ExperienceList from "./components/ExperienceList";
import ExperienceForm from "./components/ExperienceForm";
import BuyCredits from "./components/BuyCredits";
import Footer from "./components/Footer";
import Welcome from "./components/Welcome";
import ExperienceDownload from "./pages/ExperienceDownload";

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // Check authentication status on mount
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    setIsAuthenticated(!!token); // Set based on token presence
  }, []);

  const handleLogin = (userData, navigate) => {
    localStorage.setItem("authToken", userData.token); // Save token to localStorage
    setIsAuthenticated(true);
    navigate("/experience"); // Redirect to experience page
  };

  const handleRegister = (userData, navigate) => {
    localStorage.setItem("authToken", userData.token); // Save token to localStorage
    setIsAuthenticated(true);
    navigate("/experience"); // Redirect to experience page
  };

  const handleLogout = () => {
    localStorage.removeItem("authToken"); // Remove token from localStorage
    setIsAuthenticated(false);
  };

  return (
    <RecoilRoot>
      <UserProvider>
        <Router>
          <div className="App">
            <div className={`main-layout ${!isAuthenticated ? "no-shadow-layout" : ""}`}>
              {isAuthenticated && <Sidebar handleLogout={handleLogout} />}
              <div className={`App-main ${!isAuthenticated ? "no-shadow" : ""}`}>
                <Routes>
                  {/* Default Route */}
                  <Route
                    path="/"
                    element={
                      isAuthenticated ? <Navigate to="/experience" replace /> : <Welcome />
                    }
                  />

                  <Route
                    path="/download/:id"
                    element={<ExperienceDownload />}
                  />

                  {/* Public Routes */}
                  <Route
                    path="/login"
                    element={<LoginPage onLogin={handleLogin} onRegister={handleRegister} />}
                  />
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                  <Route path="/tos" element={<TermsOfService />} />

                  {/* Private Routes */}
                  <Route
                    path="/profile"
                    element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Profile />} />}
                  />
                  <Route
                    path="/experience"
                    element={<PrivateRoute isAuthenticated={isAuthenticated} component={<ExperienceList />} />}
                  />
                  <Route
                    path="/create"
                    element={<PrivateRoute isAuthenticated={isAuthenticated} component={<ExperienceForm />} />}
                  />
                  <Route
                    path="/credits"
                    element={<PrivateRoute isAuthenticated={isAuthenticated} component={<BuyCredits />} />}
                  />
                  <Route
                    path="/admin"
                    element={<PrivateRoute isAuthenticated={isAuthenticated} component={<Admin />} />}
                  />

                  {/* Fallback */}
                  <Route
                    path="*"
                    element={
                      <Navigate to={isAuthenticated ? "/experience" : "/login"} replace />
                    }
                  />
                </Routes>
              </div>
            </div>
            <Footer />
          </div>
        </Router>
      </UserProvider>
    </RecoilRoot>
  );
}

function LoginPage({ onLogin, onRegister }) {
  const navigate = useNavigate();
  return (
    <>
      <SnowScene />
      <LoginRegister
        onLogin={(userData) => onLogin(userData, navigate)}
        onRegister={(userData) => onRegister(userData, navigate)}
      />
    </>
  );
}

// PrivateRoute Component
function PrivateRoute({ isAuthenticated, component }) {
  return isAuthenticated ? component : <Navigate to="/login" replace />;
}

export default App;
