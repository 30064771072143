import { atom } from 'recoil';

export const userState = atom({
  key: 'userState',
  default: {
    id: null,
    name: '',
    email: '',
    credits: 0,
    profilePicture: null,
  },
});

export const authTokenState = atom({
  key: 'authTokenState',
  default: localStorage.getItem('authToken') || '',
});